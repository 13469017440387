.editor-toolbar {
  button {
    color: $body-color;
  }
}
.ql-formats {
  button {
      .ql-stroke {
        stroke: $body-color;
      }
  }
}