.css-2b097c-container {
  width: 100%;
  max-width: 300px;
  .css-yk16xz-control {
    background-color: $content-bg;
    width: 100%;
    max-width: 300px;
    border: 1px solid $border-color;
    color: #fff;
    &:hover {
      border: inherit;
      color: #fff;
    }
    &:focus {
      background: red;
      color: #fff;
    }
  }
  .css-1pahdxg-control {
    background-color: $content-bg;
  }
  .css-26l3qy-menu {
    background-color: $content-bg;
  }
  .css-1uccc91-singleValue {
    color: #b1b1b5;
  }
}